import _objectSpread from "D:/CODE/\u5B98\u7F51/www/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import top from "./components/top";
import connectA from "./components/connect-a"; // import connectB from "./components/connect-b";

import { mapMutations } from "vuex";
export default {
  metaInfo: {
    title: "联系我们_常达智能",
    // set a title
    meta: [{
      // set meta
      name: "keyWords",
      content: "联系我们,常达"
    }, {
      name: "description",
      content: "常达智能物联公司地址：深圳市龙岗区雅宝路1号星河WORLD A栋大厦13层 A1303B"
    }]
  },
  components: {
    top: top,
    connectA: connectA // connectB,

  },
  methods: _objectSpread({}, mapMutations(["setHeaderColorBlack", "setHeaderColorWhite"])),
  mounted: function mounted() {
    this.setHeaderColorWhite();
    new Wow.WOW({
      animateClass: "animate__animated",
      offset: 30
    }).init();
  },
  unmounted: function unmounted() {
    this.setHeaderColorBlack();
  }
};