export default {
  data: function data() {
    return {
      list: [{
        name: "微信公众号",
        qrcode: require("@/assets/connect/a/qr1.png"),
        phone: "0755-8891-0613",
        email: "info@cirteklink.com"
      }, {
        name: "RFID项目经理-王女士",
        qrcode: require("@/assets/connect/a/qr2.png"),
        phone: "+86 15883538342",
        email: "info@cirteklink.com"
      }, {
        name: "RFID业务经理-林先生",
        qrcode: require("@/assets/connect/a/qr3.png"),
        phone: "+86 13414841514",
        email: "info@cirteklink.com"
      }]
    };
  }
};